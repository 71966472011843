const Logo = () => {
  return (
    <svg viewBox="0 0 1319 417.84" className="w-full">
  <path
    d="m137.88,132.41c9.4-5.73,16.85-13.29,22.35-22.69,5.5-9.4,8.25-19.71,8.25-30.95,0-20.4-7.51-36.96-22.52-49.68-15.02-12.72-33.87-19.08-56.56-19.08H0v251h92.84c22.69,0,41.55-6.47,56.56-19.43,15.01-12.95,22.52-29.97,22.52-51.06,0-27.04-11.35-46.42-34.04-58.11ZM36,45h54.86c11.43,0,21.14,2.97,29.14,8.91,8,5.95,12,14.97,12,27.09s-4,21.14-12,27.09c-8,5.95-17.72,8.91-29.14,8.91h-54.86V45Zm87.75,171.64c-8.29,6.91-17.96,10.36-29.02,10.36h-58.73v-76h58.73c11.51,0,21.3,3.11,29.36,9.33,8.06,6.22,12.09,15.78,12.09,28.67,0,11.52-4.15,20.73-12.44,27.64Z" />
  <path
    d="m244,166.54c.45-10.11,4.3-18.73,11.56-25.86,7.25-7.12,15.86-10.69,25.84-10.69h21.6v-30c-4.6-2.22-10.33-3.33-17.22-3.33-14.93,0-27.56,6.67-37.89,20l-1.72,1.67-1.72-1.67-3.44-16.67h-31v161h34v-94.46Z" />
  <path
    d="m393.86,264.69c22.92,0,42.7-7.96,59.33-23.9,16.62-15.93,24.93-36.05,24.93-60.36s-8.31-44.42-24.93-60.36c-16.63-15.93-36.4-23.9-59.33-23.9s-42.71,7.97-59.33,23.9c-16.63,15.94-24.93,36.06-24.93,60.36s8.31,44.43,24.93,60.36c16.62,15.94,36.4,23.9,59.33,23.9Zm-36.8-122.44c8.71-10.09,20.98-15.13,36.8-15.13s28.08,5.05,36.8,15.13c8.71,10.09,13.07,22.82,13.07,38.18s-4.36,28.09-13.07,38.18c-8.72,10.09-20.98,15.13-36.8,15.13s-28.09-5.04-36.8-15.13c-8.72-10.09-13.07-22.81-13.07-38.18s4.35-28.08,13.07-38.18Z" />
  <path
    d="m590,258.25v-31.42l-20.54,3.42c-5.25,0-9.47-1.59-12.67-4.79-3.2-3.19-4.79-7.3-4.79-12.32v-82.14h35v-31h-35V31h-6.59l-27.41,34.5v34.5h-31v31h31v82.54c0,15.59,4.53,27.51,13.61,35.77,9.07,8.25,22.22,12.38,39.45,12.38l18.95-3.44Z" />
  <path
    d="m687.28,264.45c18.38,0,33.32-5.74,44.81-17.23l1.72-1.72,1.72,1.72,3.45,13.79h31.02V99h-31.02l-3.45,13.79-1.72,1.72-1.72-1.72c-11.49-11.49-26.43-17.23-44.81-17.23-22.98,0-42.51,7.93-58.6,23.78-16.09,15.86-24.13,36.08-24.13,60.66s8.04,44.81,24.13,60.66c16.08,15.86,35.62,23.78,58.6,23.78Zm-34.62-122.2c8.82-10.09,21.15-15.13,36.97-15.13,14.44,0,26.36,5.16,35.77,15.48,9.4,10.32,14.1,22.93,14.1,37.83s-4.7,27.51-14.1,37.83c-9.4,10.32-21.32,15.48-35.77,15.48-15.82,0-28.15-5.04-36.97-15.13-8.83-10.09-13.24-22.81-13.24-38.18s4.41-28.08,13.24-38.18Z" />
  <polygon points="849.4 261 846 230.09 846 0 812 0 812 230.09 815.4 261 849.4 261" />
  <path
    d="m990.49,113.76l-1.72,1.72-1.72-1.72c-3.44-5.04-8.94-9.17-16.51-12.38-7.57-3.21-15.25-4.81-23.04-4.81-19.72,0-36.4,6.82-50.04,20.46-13.64,13.64-20.46,30.32-20.46,50.04,0,24.54,10.32,42.88,30.95,55.03-20.64,11.01-30.95,27.75-30.95,50.21,0,18.57,6.99,33.41,20.98,44.54,13.98,11.12,30.49,16.68,49.53,16.68s35.54-5.56,49.53-16.68c13.98-11.12,20.98-25.97,20.98-44.54,0-22.7-10.32-39.43-30.95-50.21,20.64-12.15,30.95-30.49,30.95-55.03v-67.07h-24.07l-3.44,13.76Zm-15.57,180.85c-7,5.96-16.11,8.94-27.34,8.94s-20.35-2.98-27.34-8.94c-7-5.96-10.49-13.64-10.49-23.04s3.49-17.2,10.49-23.39c6.99-6.19,16.11-9.29,27.34-9.29s20.35,3.1,27.34,9.29c6.99,6.19,10.49,13.98,10.49,23.39s-3.5,17.08-10.49,23.04Zm-.17-99.74c-7.11,7.57-16.16,11.35-27.17,11.35s-20.07-3.78-27.17-11.35c-7.11-7.57-10.66-16.97-10.66-28.2s3.55-20.64,10.66-28.2c7.1-7.57,16.16-11.35,27.17-11.35s20.06,3.78,27.17,11.35c7.1,7.57,10.66,16.97,10.66,28.2s-3.56,20.64-10.66,28.2Z" />
  <path
    d="m1157,197.02c-.46,10.09-4.4,18.63-11.83,25.62-7.43,7-16.06,10.49-25.89,10.49-22.17,0-33.6-11.46-34.29-34.39v-99.74h-34v99.96c0,19.3,5.85,35.04,17.54,47.22,11.69,12.18,27.62,18.27,47.8,18.27,7.1,0,14.33-1.61,21.67-4.83,7.33-3.22,12.72-7.35,16.16-12.41l1.72-1.72,1.72,1.72,3.44,13.79h30.95V99h-35v98.02Z" />
  <path
    d="m1298.46,230.26c-5.25,0-9.47-1.59-12.67-4.79-3.2-3.19-4.79-7.3-4.79-12.32v-82.14h35v-31h-35V31h-6.59l-27.41,34.5v34.5h-31v31h31v82.54c0,15.59,4.53,27.51,13.61,35.77,9.07,8.25,22.22,12.38,39.45,12.38l18.95-3.44v-31.42l-20.54,3.42Z" />
  <path
    d="m56.59,317.17c-.39-.31-.9-.55-1.53-.7-.55-.08-2.31-.68-5.29-1.82-2.98-1.13-5.87-1.7-8.69-1.7-6.66,0-12.41,1.72-17.27,5.17-4.86,3.45-7.28,8.15-7.28,14.1,0,3.05.49,5.68,1.47,7.87.98,2.19,2.56,4.62,4.76,7.28l8.69,10.34c3.84,4.15,5.76,7.95,5.76,11.4,0,3.84-1.39,7.11-4.17,9.81-2.78,2.7-6.13,4.05-10.04,4.05-4.86,0-8.38-1.51-10.57-4.52-2.19-3.01-3.64-7.22-4.35-12.63-.24-1.64-.55-2.8-.94-3.47-.39-.66-1.06-1-2-1-1.25,0-2.11,1.25-2.58,3.76-1.1,6.58-1.65,11.24-1.65,13.98,0,1.57.78,2.78,2.35,3.64,6.26,3.6,12.22,5.4,17.86,5.4,7.44,0,14.02-2.11,19.74-6.34,5.72-4.23,8.58-9.67,8.58-16.33,0-3.05-2.08-7.24-6.23-12.57l-8.93-11.28c-.16-.24-.51-.68-1.06-1.35-.55-.66-.92-1.12-1.12-1.35-.2-.23-.53-.66-1-1.29-.47-.63-.8-1.08-1-1.35-.2-.27-.47-.69-.82-1.23-.35-.55-.63-.98-.82-1.29-.2-.31-.41-.71-.65-1.17-.24-.47-.41-.86-.53-1.17-.12-.31-.22-.66-.29-1.06-.08-.39-.12-.74-.12-1.06,0-3.76,1.21-6.64,3.64-8.63,2.43-2,5.56-3,9.4-3,2.82,0,5.19.9,7.11,2.7,1.92,1.8,3.23,4.19,3.94,7.17.39,1.72.7,2.82.94,3.29.23.47.7.7,1.41.7.39,0,.78-.29,1.17-.88.39-.59.63-1.31.7-2.17.39-3.05,1.02-6.46,1.88-10.22.08-.39-.08-.74-.47-1.06Z" />
  <path
    d="m109.68,372.85c-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l9.63-38.53c.24-1.1-.08-2.06-.94-2.88-.86-.82-2.39-1.23-4.58-1.23-1.02,0-1.72.47-2.11,1.41-1.17,2.66-2.15,3.99-2.94,3.99-.16,0-.57-.12-1.23-.35-.67-.23-1.31-.35-1.94-.35-3.52,0-8.38,2.29-14.57,6.87-6.19,4.58-11.79,10.28-16.8,17.09-5.01,6.81-7.52,12.88-7.52,18.21,0,2.19.78,3.86,2.35,4.99,1.57,1.13,3.6,1.7,6.11,1.7,9.24,0,17.89-5.44,25.96-16.33.24-.39.47-.55.71-.47.23.08.31.39.23.94-.08.24-.2.67-.35,1.29-.31,1.49-.63,3.05-.94,4.7-.31,1.65-.47,3.21-.47,4.7s.35,2.72,1.06,3.7c.7.98,1.8,1.47,3.29,1.47,3.37,0,6.95-1.63,10.75-4.87,3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53Zm-17.68-12.92c-3.17,4.78-6.97,9.14-11.39,13.1-4.43,3.96-8.16,5.93-11.22,5.93-1.41,0-2.11-1.1-2.11-3.29,0-2.74,1.68-6.62,5.05-11.63,3.37-5.01,7.07-9.44,11.1-13.27,4.03-3.84,6.95-5.76,8.75-5.76,1.25,0,2.33.45,3.23,1.35.9.9,1.35,1.78,1.35,2.64,0,2.51-1.59,6.15-4.76,10.92Z" />
  <path
    d="m166.3,372.85c-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l9.87-35.36c.31-1.33.12-2.64-.59-3.93-.7-1.29-2.11-1.94-4.23-1.94-1.17,0-1.88.47-2.11,1.41-2.27,8.46-4.74,14.96-7.4,19.5-3.13,5.17-6.7,10.2-10.69,15.1-3.99,4.9-7.32,7.34-9.99,7.34-.94,0-1.53-.65-1.76-1.94-.24-1.29-.16-2.56.24-3.82l8.58-29.72c.63-1.17.76-2.78.41-4.82-.35-2.04-1.59-3.05-3.7-3.05-2.9,0-6.64,1.67-11.22,4.99-4.58,3.33-6.87,5.66-6.87,6.99,0,1.88.43,2.82,1.29,2.82.39,0,1.76-.94,4.11-2.82,2.35-1.88,4.15-2.82,5.4-2.82,1.1,0,1.17,1.45.24,4.35l-8.58,29.72c-.39,1.72,0,3.64,1.17,5.76,1.17,2.11,2.74,3.17,4.7,3.17,7.28,0,15.55-6.97,24.79-20.91.08-.16.21-.27.41-.35.19-.08.35-.06.47.06s.14.37.06.76c-4.86,13.63-4.74,20.44.35,20.44,3.37,0,6.95-1.63,10.75-4.87,3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53Z" />
  <path
    d="m201.19,335.26c-6.74,0-13.04,3.66-18.91,10.98-5.87,7.32-8.81,15.29-8.81,23.91,0,4.46,1.1,8.42,3.29,11.86,2.19,3.45,5.21,5.17,9.05,5.17,4.15,0,8.3-1.31,12.45-3.94,4.15-2.62,6.23-4.83,6.23-6.64s-.55-2.82-1.65-2.82c-.23,0-.98.47-2.23,1.41-1.25.94-2.78,1.86-4.58,2.76-1.8.9-3.49,1.35-5.05,1.35-2.43,0-4.23-1.1-5.4-3.29-1.17-2.19-1.76-4.82-1.76-7.87,0-1.33.19-2.64.59-3.94.39-1.29.86-1.94,1.41-1.94,5.17,0,10.9-1.9,17.21-5.7,6.3-3.8,9.46-7.89,9.46-12.28,0-6.03-3.76-9.05-11.28-9.05Zm-2.64,17.56c-3.41,3.41-7.26,5.11-11.57,5.11-.47,0-.71-.27-.71-.82,0-2.9,1.72-6.48,5.17-10.75,3.45-4.27,6.11-6.4,7.99-6.4,2.82,0,4.23.94,4.23,2.82,0,3.29-1.7,6.64-5.11,10.04Z" />
  <path
    d="m258.64,336.32c-1.25-.7-2.58-1.06-3.99-1.06-2.58,0-5.13,1.23-7.64,3.7-2.51,2.47-6.66,7.85-12.45,16.15-.24.31-.57.37-1,.18-.43-.19-.57-.49-.41-.88,1.88-5.01,3.13-9.08,3.76-12.22.31-1.72.04-3.31-.82-4.76-.86-1.45-1.96-2.17-3.29-2.17-3.45,0-6.97,1.49-10.57,4.46-3.6,2.98-5.4,5.29-5.4,6.93s.43,2.47,1.29,2.47c.31,0,1.54-.88,3.7-2.64,2.15-1.76,4.09-2.64,5.82-2.64.86,0,1.33.59,1.41,1.76.08,1.17-.12,2.23-.59,3.17l-10.45,29.25c-1.17,3.37-1.76,5.52-1.76,6.46,0,.39.68,1,2.06,1.82,1.37.82,2.53,1.19,3.47,1.12.86-.08,1.8-1.29,2.82-3.64,2.74-6.03,6.87-13.7,12.39-23.02,5.52-9.32,9.26-13.98,11.22-13.98.63,0,1.51.41,2.64,1.23,1.13.82,2.17,1.23,3.11,1.23,1.17,0,2.56-1.13,4.17-3.41,1.6-2.27,2.41-4.5,2.41-6.7,0-1.17-.63-2.11-1.88-2.82Z" />
  <path
    d="m294.93,337h-11.86c1.17-3.93,1.76-7.15,1.76-9.02s-.67-2.94-2-2.94c-1.65,0-3.19.76-4.64,2.29-1.45,1.53-2.25,2.76-2.41,3.7l-1.64,5.97h-4.7c-1.17.61-2.29,1.18-3.35,2.31-1.06,1.14-1.59,2.21-1.59,3.23,0,1.1.9,1.64,2.7,1.46h5.52l-9.16,34.96c-.47,1.88-.7,3.09-.7,3.64,0,3.21,1.72,4.82,5.17,4.82s6.95-1.63,10.75-4.87c3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l8.58-32.49h10.92c1.17.19,2.37-.42,3.58-1.63s1.82-2.21,1.82-3c0-1.17-1.02-1.76-3.05-2.37Z" />
  <path
    d="m324.77,335.26c-6.74,0-13.04,3.66-18.91,10.98-5.87,7.32-8.81,15.29-8.81,23.91,0,4.46,1.1,8.42,3.29,11.86,2.19,3.45,5.21,5.17,9.05,5.17,4.15,0,8.3-1.31,12.45-3.94,4.15-2.62,6.23-4.83,6.23-6.64s-.55-2.82-1.65-2.82c-.23,0-.98.47-2.23,1.41-1.25.94-2.78,1.86-4.58,2.76-1.8.9-3.49,1.35-5.05,1.35-2.43,0-4.23-1.1-5.4-3.29-1.17-2.19-1.76-4.82-1.76-7.87,0-1.33.19-2.64.59-3.94.39-1.29.86-1.94,1.41-1.94,5.17,0,10.9-1.9,17.21-5.7,6.3-3.8,9.46-7.89,9.46-12.28,0-6.03-3.76-9.05-11.28-9.05Zm-2.64,17.56c-3.41,3.41-7.26,5.11-11.57,5.11-.47,0-.71-.27-.71-.82,0-2.9,1.72-6.48,5.17-10.75,3.45-4.27,6.11-6.4,7.99-6.4,2.82,0,4.23.94,4.23,2.82,0,3.29-1.7,6.64-5.11,10.04Z" />
  <path
    d="m362.95,374.38c0-1.02-.28-1.53-.82-1.53-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l7.05-28.31c1.17-4.85,1.76-8.18,1.76-9.99,0-1.96-.63-2.94-1.88-2.94-1.88,0-5.23,1.74-10.04,5.23-4.82,3.49-7.22,5.86-7.22,7.11,0,1.64.47,2.47,1.41,2.47.08,0,.94-.57,2.58-1.7,1.64-1.13,2.94-1.7,3.88-1.7.24,0,.41.08.53.23.12.16.18.37.18.65s-.02.51-.06.71c-.04.2-.12.49-.24.88-.12.39-.18.63-.18.7l-7.28,29.13c-.47,1.88-.7,3.09-.7,3.64,0,3.21,1.72,4.82,5.17,4.82s6.95-1.63,10.75-4.87c3.8-3.25,5.7-5.97,5.7-8.16Z" />
  <path
    d="m356.43,313.94c-1.29,1.37-1.94,2.92-1.94,4.64s.43,2.94,1.29,3.88c.86.94,2.07,1.41,3.64,1.41,1.72,0,3.23-.71,4.52-2.11,1.29-1.41,1.94-2.98,1.94-4.7,0-1.57-.45-2.82-1.35-3.76-.9-.94-2.1-1.41-3.58-1.41-1.72,0-3.23.69-4.52,2.06Z" />
  <path
    d="m418.63,335.96c-.7,0-2.31.18-4.82.53-2.51.35-3.84.53-3.99.53-1.96,0-4.37-.29-7.22-.88-2.86-.59-4.37-.88-4.52-.88-5.64,0-10.53,1.49-14.68,4.46-4.15,2.98-7.13,6.54-8.93,10.69-1.8,4.15-2.7,8.54-2.7,13.16,0,2.11.94,4.19,2.82,6.23,1.88,2.04,3.91,3.45,6.11,4.23.31.08.59.16.82.24.78.39.9.74.35,1.06-3.37,1.64-5.05,3.56-5.05,5.76,0,1.02.94,2.35,2.82,3.99.94.78.94,1.37,0,1.76-5.17,1.88-9.97,4.62-14.39,8.22-4.42,3.6-6.64,6.85-6.64,9.75,0,3.13,1.23,5.72,3.7,7.75,2.47,2.04,5.29,3.43,8.46,4.17,3.17.74,6.6,1.12,10.28,1.12,7.44,0,14.49-1.59,21.15-4.76,6.66-3.17,9.99-7.07,9.99-11.69,0-1.33-.33-2.6-1-3.82-.67-1.22-1.8-2.45-3.41-3.7-1.61-1.25-2.94-2.23-3.99-2.94-1.06-.71-2.86-1.78-5.4-3.23-2.55-1.45-4.21-2.41-4.99-2.88-.47-.24-1.17-.61-2.11-1.12-.94-.51-1.74-.96-2.41-1.35-.67-.39-1.37-.84-2.11-1.35-.74-.51-1.31-1-1.7-1.47-.39-.47-.59-.9-.59-1.29,0-1.49,1.64-2.7,4.93-3.64,5.17-1.41,9.59-4.42,13.27-9.05,3.68-4.62,5.52-9.83,5.52-15.62,0-.55-.08-1.49-.24-2.82-.16-1.33-.2-2.35-.12-3.05.08-.7.35-1.06.82-1.06.31,0,1.74.06,4.29.18,2.54.12,4.36.18,5.46.18,3.21,0,4.82-1.25,4.82-3.76s-1.53-3.64-4.58-3.64Zm-32.89,54.04c4.62,1.64,9.16,4.35,13.63,8.11.08.08.27.23.59.47.31.24.51.41.59.53.08.12.25.29.53.53.27.24.47.45.59.65.12.19.25.43.41.7.16.27.27.57.35.88.08.31.16.66.23,1.06.08.39.12.78.12,1.17,0,1.88-.8,3.45-2.41,4.7-1.61,1.25-3.43,2.09-5.46,2.53-2.04.43-4.19.65-6.46.65-5.33,0-10.04-.94-14.16-2.82s-6.17-4.11-6.17-6.7c0-2.19,1.72-4.52,5.17-6.99,3.45-2.47,6.5-4.17,9.16-5.11,1.49-.47,2.58-.59,3.29-.35Zm8.87-27.08c-3.09,5.05-6.25,7.58-9.46,7.58-1.25,0-2.27-.55-3.05-1.64-.78-1.1-1.17-2.31-1.17-3.64,0-5.95,1.39-11.53,4.17-16.74,2.78-5.21,6.28-7.81,10.51-7.81,2.43,0,3.64,2.27,3.64,6.81,0,5.25-1.55,10.4-4.64,15.45Z" />
  <path
    d="m461.15,335.26c-2.51,0-5.64.94-9.4,2.82-3.76,1.88-7.24,4.31-10.46,7.28-.94.63-1.45.72-1.53.29-.08-.43,0-1.15.24-2.17l6.23-24.9c1.17-4.85,1.76-8.18,1.76-9.99,0-1.96-.94-2.94-2.82-2.94-2.04,0-5.11.59-9.22,1.76-4.11,1.17-6.17,2.19-6.17,3.05,0,.63,1.37,1.45,4.11,2.47,2.74,1.02,4.11,1.64,4.11,1.88,0,.63-.35,2.43-1.06,5.4l-11.86,47.46c-.94,3.76-1.41,6.58-1.41,8.46,0,2.98,1.12,5.6,3.35,7.87,2.23,2.27,4.8,3.41,7.69,3.41,4.7,0,9.77-1.96,15.21-5.87,5.44-3.91,10.1-9.16,13.98-15.74,3.88-6.58,5.82-13.23,5.82-19.97,0-2.74-.84-5.19-2.53-7.34-1.69-2.15-3.7-3.23-6.05-3.23Zm-8.34,34.71c-4.62,6.93-9.4,10.4-14.33,10.4-1.41,0-2.72-.68-3.93-2.06-1.22-1.37-1.82-3-1.82-4.88,0-2.98,1.12-6.85,3.35-11.63,2.23-4.78,5.27-9.16,9.1-13.16,3.84-3.99,7.56-5.99,11.16-5.99,2.27,0,3.41,1.84,3.41,5.52,0,7.6-2.31,14.86-6.93,21.79Z" />
  <path
    d="m527.05,372.85c-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l9.63-38.53c.24-1.1-.08-2.06-.94-2.88-.86-.82-2.39-1.23-4.58-1.23-1.02,0-1.72.47-2.11,1.41-1.17,2.66-2.15,3.99-2.94,3.99-.16,0-.57-.12-1.23-.35-.67-.23-1.31-.35-1.94-.35-3.52,0-8.38,2.29-14.57,6.87-6.19,4.58-11.79,10.28-16.8,17.09-5.01,6.81-7.52,12.88-7.52,18.21,0,2.19.78,3.86,2.35,4.99,1.57,1.13,3.6,1.7,6.11,1.7,9.24,0,17.89-5.44,25.96-16.33.24-.39.47-.55.71-.47.23.08.31.39.23.94-.08.24-.2.67-.35,1.29-.31,1.49-.63,3.05-.94,4.7-.31,1.65-.47,3.21-.47,4.7s.35,2.72,1.06,3.7c.7.98,1.8,1.47,3.29,1.47,3.37,0,6.95-1.63,10.75-4.87,3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53Zm-17.68-12.92c-3.17,4.78-6.97,9.14-11.39,13.1-4.43,3.96-8.16,5.93-11.22,5.93-1.41,0-2.11-1.1-2.11-3.29,0-2.74,1.68-6.62,5.05-11.63,3.37-5.01,7.07-9.44,11.1-13.27,4.03-3.84,6.95-5.76,8.75-5.76,1.25,0,2.33.45,3.23,1.35.9.9,1.35,1.78,1.35,2.64,0,2.51-1.59,6.15-4.76,10.92Z" />
  <path
    d="m570.05,336.73c-1.88-.98-3.96-1.47-6.23-1.47-5.01,0-9.61,1.43-13.8,4.29-4.19,2.86-7.66,6.75-10.4,11.69-3.6,6.42-5.4,13-5.4,19.74,0,4.39,1.04,8.22,3.11,11.51,2.07,3.29,5.07,4.93,8.99,4.93,4.46,0,8.79-1.86,12.98-5.58,4.19-3.72,6.28-6.56,6.28-8.52,0-.94-.31-1.66-.94-2.17-.63-.51-1.29-.76-2-.76-.23,0-1,.76-2.29,2.29-1.29,1.53-2.84,3.05-4.64,4.58-1.8,1.53-3.49,2.29-5.05,2.29-4.07,0-6.11-3.01-6.11-9.05,0-6.58,1.68-13.23,5.05-19.97,3.37-6.73,6.73-10.1,10.1-10.1.63,0,1.37.26,2.23.76.86.51,1.49,1.04,1.88,1.59,1.65,2.66,3.29,3.99,4.93,3.99.86,0,1.76-.72,2.7-2.17.94-1.45,1.41-2.64,1.41-3.58,0-1.88-.94-3.31-2.82-4.29Z" />
  <path
    d="m624.44,372.38c-.39,0-1.65,1.06-3.76,3.17-2.11,2.11-3.99,3.17-5.64,3.17-1.1,0-2.29-.74-3.58-2.23-1.29-1.49-2.7-3.29-4.23-5.4-1.53-2.11-2.76-3.6-3.7-4.46-.94-.78-.86-1.37.24-1.76,5.4-2.19,10.04-5.21,13.92-9.05,3.88-3.84,5.82-7.52,5.82-11.04,0-2.66-.67-4.91-2-6.75-1.33-1.84-3.29-2.76-5.87-2.76-1.02,0-2.15.24-3.41.7-1.25.47-2.51,1.14-3.76,2-1.25.86-2.47,1.76-3.64,2.7-1.17.94-2.37,1.98-3.58,3.11-1.22,1.14-2.21,2.1-3,2.88-.78.78-1.57,1.59-2.35,2.41-.78.82-1.21,1.27-1.29,1.35-.24.24-.45.43-.65.59-.2.16-.39.28-.59.35-.2.08-.29,0-.29-.24s.04-.59.12-1.06l7.87-31.48c1.17-4.85,1.76-8.18,1.76-9.99,0-1.96-.94-2.94-2.82-2.94-2.04,0-5.11.59-9.22,1.76-4.11,1.17-6.17,2.19-6.17,3.05,0,.63,1.37,1.45,4.11,2.47,2.74,1.02,4.11,1.64,4.11,1.88,0,.63-.35,2.43-1.06,5.4l-13.16,52.75c-1.17,4.86-1.53,8.69-1.06,11.51.08.47.55,1.1,1.41,1.88.86.78,1.72,1.13,2.58,1.06,1.1-.08,2.04-1.29,2.82-3.64.63-1.96,1.19-3.78,1.7-5.46.51-1.68.9-2.98,1.17-3.88.27-.9.65-1.9,1.12-3,.39-.94.76-1.65,1.12-2.11.35-.47.63-.74.82-.82.19-.08.53-.12,1-.12,2.27,0,4.35,1.14,6.23,3.41,1.1,1.25,2.7,3.41,4.82,6.46,2.11,3.05,3.88,5.34,5.29,6.87,1.41,1.53,2.74,2.29,3.99,2.29,3.76,0,6.97-1.59,9.63-4.76,2.66-3.17,3.99-6.09,3.99-8.75,0-1.02-.28-1.53-.82-1.53Zm-30.66-8.22c-1.02,0-1.53-.74-1.53-2.23,0-1.64,2.49-5.21,7.46-10.69,4.97-5.48,8.56-8.22,10.75-8.22s3.17,1.49,3.17,4.46c0,3.92-2.37,7.68-7.11,11.28-4.74,3.6-8.99,5.4-12.75,5.4Z" />
  <path
    d="m659.62,337.37c-1.69-1.41-3.54-2.11-5.58-2.11-4.46,0-8.4,1.57-11.81,4.7-3.41,3.13-5.11,6.66-5.11,10.57,0,1.8.43,3.49,1.29,5.05,6.19,10.42,9.28,16.92,9.28,19.5,0,1.49-.41,2.78-1.23,3.88-.82,1.1-1.9,1.64-3.23,1.64-2.11,0-4.39-.78-6.81-2.35l-3.76-2.35c-1.02,0-1.92.35-2.7,1.06-.78.7-1.17,1.53-1.17,2.47,0,2.27.8,4.17,2.41,5.7,1.6,1.53,3.58,2.29,5.93,2.29,4.46,0,8.75-1.55,12.86-4.64,4.11-3.09,6.17-6.91,6.17-11.45,0-2.27-.94-5.13-2.82-8.58-.39-.7-.94-1.68-1.65-2.94-.7-1.25-1.25-2.27-1.64-3.05-.39-.78-.88-1.72-1.47-2.82-.59-1.1-1.06-2.02-1.41-2.76-.35-.74-.7-1.51-1.06-2.29-.35-.78-.61-1.45-.76-2-.16-.55-.24-1.02-.24-1.41,0-.86.27-1.72.82-2.58.55-.86,1.21-1.29,2-1.29,1.88,0,4.07.78,6.58,2.35,2.51,1.57,3.84,2.35,3.99,2.35.86,0,1.68-.51,2.47-1.53.78-1.02,1.17-1.92,1.17-2.7,0-1.72-.84-3.29-2.53-4.7Z" />
  <path
    d="m700.67,337h-11.87c1.17-3.93,1.76-7.15,1.76-9.02s-.67-2.94-2-2.94c-1.65,0-3.19.76-4.64,2.29-1.45,1.53-2.25,2.76-2.41,3.7l-1.64,5.97h-4.7c-1.17.61-2.29,1.18-3.35,2.31-1.06,1.14-1.59,2.21-1.59,3.23,0,1.1.9,1.64,2.7,1.46h5.52l-9.16,34.96c-.47,1.88-.7,3.09-.7,3.64,0,3.21,1.72,4.82,5.17,4.82s6.95-1.63,10.75-4.87c3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l8.57-32.49h10.93c1.18.19,2.37-.42,3.58-1.63,1.21-1.21,1.82-2.21,1.82-3,0-1.17-1.02-1.76-3.05-2.37Z" />
  <path
    d="m752.24,372.85c-.39,0-1.68.94-3.88,2.82-2.19,1.88-4.11,2.82-5.76,2.82-.94,0-1.25-.66-.94-2l9.87-35.36c.31-1.33.12-2.64-.59-3.93-.71-1.29-2.11-1.94-4.23-1.94-1.18,0-1.88.47-2.11,1.41-2.27,8.46-4.74,14.96-7.4,19.5-3.13,5.17-6.7,10.2-10.69,15.1-4,4.9-7.32,7.34-9.99,7.34-.94,0-1.53-.65-1.76-1.94-.24-1.29-.16-2.56.23-3.82l8.58-29.72c.63-1.17.76-2.78.41-4.82-.35-2.04-1.59-3.05-3.7-3.05-2.9,0-6.64,1.67-11.22,4.99-4.58,3.33-6.87,5.66-6.87,6.99,0,1.88.43,2.82,1.29,2.82.39,0,1.76-.94,4.11-2.82,2.35-1.88,4.15-2.82,5.4-2.82,1.1,0,1.17,1.45.24,4.35l-8.58,29.72c-.39,1.72,0,3.64,1.17,5.76,1.17,2.11,2.74,3.17,4.7,3.17,7.28,0,15.54-6.97,24.79-20.91.08-.16.21-.27.41-.35.19-.08.35-.06.47.06.12.12.13.37.06.76-4.86,13.63-4.74,20.44.35,20.44,3.37,0,6.95-1.63,10.75-4.87,3.8-3.25,5.7-5.97,5.7-8.16,0-1.02-.28-1.53-.82-1.53Z" />
  <path
    d="m797.82,335.26c-2.51,0-5.64.94-9.4,2.82-3.76,1.88-7.24,4.31-10.45,7.28-.94.63-1.45.72-1.53.29-.08-.43,0-1.15.23-2.17l6.23-24.9c1.18-4.85,1.76-8.18,1.76-9.99,0-1.96-.94-2.94-2.82-2.94-2.04,0-5.11.59-9.22,1.76-4.11,1.17-6.17,2.19-6.17,3.05,0,.63,1.37,1.45,4.11,2.47,2.74,1.02,4.11,1.64,4.11,1.88,0,.63-.35,2.43-1.06,5.4l-11.86,47.46c-.94,3.76-1.41,6.58-1.41,8.46,0,2.98,1.12,5.6,3.35,7.87,2.23,2.27,4.8,3.41,7.7,3.41,4.7,0,9.77-1.96,15.21-5.87,5.44-3.91,10.1-9.16,13.98-15.74,3.88-6.58,5.82-13.23,5.82-19.97,0-2.74-.84-5.19-2.53-7.34-1.69-2.15-3.7-3.23-6.05-3.23Zm-8.34,34.71c-4.62,6.93-9.4,10.4-14.33,10.4-1.41,0-2.72-.68-3.94-2.06-1.21-1.37-1.82-3-1.82-4.88,0-2.98,1.12-6.85,3.35-11.63,2.23-4.78,5.27-9.16,9.1-13.16,3.84-3.99,7.56-5.99,11.16-5.99,2.27,0,3.41,1.84,3.41,5.52,0,7.6-2.31,14.86-6.93,21.79Z" />
  <path
    d="m840.82,335.26c-6.74,0-13.04,3.66-18.91,10.98-5.87,7.32-8.81,15.29-8.81,23.91,0,4.46,1.1,8.42,3.29,11.86,2.19,3.45,5.21,5.17,9.05,5.17,4.15,0,8.3-1.31,12.45-3.94,4.15-2.62,6.23-4.83,6.23-6.64s-.55-2.82-1.64-2.82c-.24,0-.98.47-2.23,1.41-1.25.94-2.78,1.86-4.58,2.76-1.8.9-3.48,1.35-5.05,1.35-2.43,0-4.23-1.1-5.4-3.29-1.17-2.19-1.76-4.82-1.76-7.87,0-1.33.19-2.64.59-3.94.39-1.29.86-1.94,1.41-1.94,5.17,0,10.9-1.9,17.21-5.7,6.3-3.8,9.46-7.89,9.46-12.28,0-6.03-3.76-9.05-11.28-9.05Zm-2.64,17.56c-3.41,3.41-7.27,5.11-11.57,5.11-.47,0-.71-.27-.71-.82,0-2.9,1.72-6.48,5.17-10.75,3.44-4.27,6.11-6.4,7.99-6.4,2.82,0,4.23.94,4.23,2.82,0,3.29-1.7,6.64-5.11,10.04Z" />
</svg>
  );
}

export default Logo;